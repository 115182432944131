// JS FRONT OFFICE
window.showMessage = showMessage;

// INPUT FILE CUSTOM
var inputFileItems = document.querySelectorAll(".input-file");

for (var i=0; i < inputFileItems.length; i++ ) {
    var fileInput  = inputFileItems[i].children[0],
        button     = inputFileItems[i].children[1],
        the_return = inputFileItems[i].children[2];

    button.addEventListener( "keydown", function( event ) {
        if ( event.keyCode == 13 || event.keyCode == 32 ) {
            this.previousElementSibling.focus();
        }
    });

    button.addEventListener( "click", function( event ) {
        this.previousElementSibling.focus();
        return false;
    });

    fileInput.addEventListener( "change", function( event ) {
        var this_return = this.nextElementSibling.nextElementSibling;
        this_return.innerHTML = this.value.replace('C:\\fakepath\\' , ' ');
    });
} // END

// Fonction qui affiche une notification en bas du header pendant 3 secondes
// type : ( string ) class qu'aura l'élément ajouté ( valid, error ou delete )
// message : ( string ) message affiché
function showMessage(type, message) {
    var alert = $('<p>');
    alert.addClass('msg-info flash');
    alert.addClass(type);
    alert.html(message);

    $('body').append(alert);

    setTimeout(function(){
        alert.addClass('open');
    }, 100)

    setTimeout(function(){
        alert.removeClass('open');
    }, 3000)

    setTimeout(function(){
        alert.remove();
    }, 3500)
}

$(document).ready(function() {

    // BOUTON TOGGLE MAIN NAV
    $('.toggle-nav').click(function(){
        $(this).toggleClass("menu-open");
        $('.menu').toggleClass("showing-nav");
        return false;
    });

    // POPUP
    $('*[data-popup]').click(function(){
        $('body').addClass('mode-popup');
        $('#'+$(this).attr('data-popup')).addClass('open');
    })

    // CLOSE POPUP
    $('.close-popup').click(function(){
        $('body').removeClass('mode-popup');
        $(this).parent('.popup').removeClass('open');
    })

    // POPUP SEARCH
    $('.btn-search').click(function(){
        $('body').css('overflow', 'hidden');
        $('#search-box').addClass('open');
    })

    // CLOSE POPUP
    $('.close-search').click(function(){
        $('body').removeAttr('style');
        $('#search-box').removeClass('open');
    })

    // SELECT JS
    $('.dropdown-js').click(function(e){
        $(this).toggleClass('open');
    })

    // SCROLLTO
    $('.js-scrollTo').on('click', function() { // Au clic sur un élément
        var page = $(this).attr('href'); // Page cible
        var speed = 750; // Durée de l'animation (en ms)

        $('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
        return false;
    });

    // VIDEOS
    $('[data-play]').on('click', function(ev) {
        $(this).fadeOut();
        $('#'+$(this).attr('data-play'))[0].src += "&autoplay=1";
        ev.preventDefault();
    });

    // FAST CONTACT
    $('.toggle-fast-contact').click(function(){
        $('.fast-contact').toggleClass("is-open");
        $('.trigger-fast-contact').toggleClass("fc-is-open");
        return false;
    });

    $('.trigger-fast-contact').click(function() {
        if($('.fast-contact').hasClass("is-open")) {
            return false;
        } else {
            $('.fast-contact').addClass("is-open");
            $(this).addClass("fc-is-open");
        }
    })

}); // FIN DOCUMENT READY

$(document).click(function(event) {
    if(!$(event.target).closest('.dropdown-js').length) {
        $('.dropdown-js').removeClass('open');
    }
})
